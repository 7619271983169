import React, { useEffect, useState } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'

interface PageProps {
  pageContext: {
    locale: string
  }
}

const DescargaQR: React.FC<PageProps> = ({ pageContext }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    // Lógica de redirección basada en el agente de usuario
    if (typeof window !== 'undefined' && window.navigator) {
      const userAgent = window.navigator.userAgent.toLowerCase()
      if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = 'https://apps.apple.com/mx/app/abordomovil/id1500142376'
      } else if (/android/.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=org.allabordo.abordoApp&hl=es'
      } else {
        window.location.href = '/descarga'
      }
    } else {
      setMounted(true)
    }
  }, [])

  return (
    <Layout locale={pageContext.locale}>
      <AAnimate type="fadeIn" in={mounted}>
        <div
          style={{
            textAlign: 'center',
            height: '40vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <h2 style={{ color: '#27387e', fontWeight: '500', fontFamily: 'Open Sans' }}>Redireccionando...</h2>
          <p>Estás siendo dirigido a la tienda para descargar la aplicación de AllAbordo.</p>
        </div>
      </AAnimate>
    </Layout>
  )
}

export default DescargaQR
